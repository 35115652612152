<template>

    <div v-if="loadingStep" class="spinner-wrapper">
        <div class="loader"></div>
    </div>


    <div v-else class="container mx-auto p-6 space-y-6">
        <!-- Document ID Input and Navigation -->
        <div class="flex items-center justify-between">
            <div class="ml-6 flex items-center text-lg">
                <input type="text" v-model="documentId"
                    class="border-b border-gray-300 mr-4 placeholder-black focus:outline-none w-64"
                    placeholder="Id / Nombre del documento" />
                <i class="fas fa-edit text-gray-400"></i>
            </div>

            <!-- Navigation Buttons -->
            <div class="flex items-center space-x-4 mr-6">
                <button @click="$emit('previous-step')"
                    class="text-blue-500 hover:text-blue-400 font-bold flex items-center space-x-2" :disabled="loading">
                    <i class="fas fa-arrow-left text-white bg-blue-500 hover:bg-blue-400 rounded-full p-1"></i>
                    <span>Volver</span>
                </button>
                <button @click="handleNextStep" :class="[
                    'font-bold flex items-center space-x-2',
                    { 'text-blue-500 hover:text-blue-400': isFileUploaded && isPdfLoaded && !loading, 'text-gray-400 cursor-not-allowed': loading || !isFileUploaded || !isPdfLoaded }
                ]" :disabled="loading || !isFileUploaded || !isPdfLoaded">
                    <span>Continuar</span>
                    <i :class="{
                        'fas fa-arrow-right ml-2 rounded-full p-1': true,
                        'text-white bg-blue-500 hover:bg-blue-400': isFileUploaded && isPdfLoaded && !loading,
                        'bg-gray-500': !isFileUploaded || !isPdfLoaded || loading
                    }"></i>
                </button>
            </div>
        </div>


        <div class="p-6">
            <div class="flex gap-8">
                <!-- Left Section - Signature Options and Checkboxes -->
                <div class="w-1/3">
                    <ul class="space-y-4">
                        <li class="flex items-center mb-2">
                            <p>Configuración de firma:</p>
                            <div class="flex items-center">
                                <select v-model="selectedCampainName" @change="changeCampaign"
                                    class="border-gray-300 border-b-2 w-35 focus:outline-none ml-2 placeholder-gray-800">
                                    <option v-for="item in campains" :key="item.id" :value="item.name">{{ item.name }}
                                    </option>
                                </select>
                                <i class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer ml-2"
                                    title="Si tienes documentos recurrentes con una ubicación específica de firma, simplemente posiciona las firmas donde te gustaría, guarda la configuración y selecciona la configuración en futuros envíos."></i>
                            </div>
                        </li>
                    </ul>

                    <!-- Checkboxes -->
                    <div>
                        <div class="flex items-center space-x-2">
                            <input type="checkbox" v-model="send_dni" class="cursor-pointer h-4 w-4">
                            <p>Prueba de Vida (foto selfie + DNI/CI)</p>
                            <i class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer"
                                title="El firmante deberá tomar una foto del documento de identidad + foto selfie para firmar (Wapi no valida las fotos). Las fotos quedarán en el informe de firmas."></i>
                        </div>

                        <div v-if="selectedCampaignId !== 0" class="flex items-center space-x-2">
                            <input type="checkbox" v-model="validate_dni" class="cursor-pointer h-4 w-4">
                            <p>Validar DNI/CI</p>
                            <i class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer"
                                title="Requiere Foto de DNI/CI para validación del Firmante."></i>
                        </div>

                        <div v-if="selectedCampaignId !== 0" class="flex items-center space-x-2">
                            <input type="checkbox" v-model="todas_las_hojas" class="cursor-pointer h-4 w-4">
                            <p>Firma en todas las hojas</p>
                            <i class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer"
                                title="Se aplica la firma en todas las hojas."></i>
                        </div>


                        <!-- Guardar Plantilla Button -->
                        <button @click="openPopup" :class="[
                            'mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-3 rounded',
                            { 'cursor-not-allowed opacity-50': loading || !isFileUploaded || !isPdfLoaded || selectedCampaignId !== 1 || limitTemplateSave }
                        ]"
                            :disabled="loading || !isFileUploaded || !isPdfLoaded || selectedCampaignId !== 1 || limitTemplateSave">
                            Guardar Plantilla
                        </button>


                        <!-- Popup Modal -->
                        <div v-if="showPopup"
                            class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
                            <div class="bg-white p-6 rounded-md shadow-md w-96">
                                <h2 class="text-lg font-semibold mb-3">Guardar Plantilla</h2>
                                <input type="text" v-model="templateName" class="w-full border p-2 rounded-md"
                                    placeholder="Ingrese el nombre de la plantilla">

                                <div class="flex justify-end mt-4 space-x-2">
                                    <button @click="saveTemplate"
                                        class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded z-index-50">
                                        Guardar
                                    </button>
                                    <button @click="closePopup"
                                        class="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded">
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Confirmation Popup -->
                        <div v-if="confirmationPopup"
                            class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
                            <div class="bg-white p-6 rounded-md shadow-md w-96">
                                <h2 class="text-lg font-semibold mb-3">Plantilla Guardada</h2>
                                <p class="text-gray-500">La plantilla ha sido guardada exitosamente.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Section - File Upload and PDF Viewer -->
                <div class="w-2/3">
                    <!-- Display Uploaded Files -->
                    <div v-if="fileNames.length > 0" class="space-y-2 mb-4">
                        <div v-for="(name, index) in fileNames" :key="index"
                            class="bg-blue-100 p-2 rounded-md text-center flex justify-between items-center w-96 relative">

                            <!-- Filename (adjusted to fit text size and aligned right) -->
                            <span class="text-black">{{ index + 1 }}. {{ name }}</span>


                            <!-- Remove Button -->
                            <button @click="removeFile(index)" class="text-gray-800 hover:bg-blue-300 px-2 rounded-sm">
                                <i class="fas fa-times text-lg cursor-pointer"></i>
                            </button>
                        </div>
                    </div>

                    <!-- PDF Viewer -->
                    <div v-if="base64Doc" class="flex overflow-hidden">
                        <PdfViewer :pdfData="base64Doc" :signers="signersDataCopy" @signers-load="onPdfLoaded"
                            @signers-not-load="onPdfNotLoaded" @coords="onCoords" />
                    </div>

                    <!-- Loading Message -->
                    <div v-if="loading" class="text-center">
                        <div class="inline-block w-24 h-1 bg-blue-500 rounded-full animate-pulse"></div>
                        <p class="mt-2 text-gray-500">Cargando...</p>
                    </div>

                    <!-- File Upload Area -->
                    <div v-if="!isFileUploaded" class="flex ">
                        <label for="fileInput"
                            class="w-96 h-52 flex flex-col items-center text-center px-4 py-3 hover:bg-blue-100 cursor-pointer border-4 border-blue-400 border-dashed"
                            @dragover.prevent @drop.prevent="handleDrop">
                            <i class="fas fa-file-pdf text-red-500 fa-4x mb-4"></i>
                            <p class="text-gray-400">Arrastrar y soltar el documento .pdf aquí o:</p>
                            <button @click="openFileInput"
                                class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-bold rounded-full mt-2">
                                <i class="fas fa-search-plus mr-1"></i>Seleccionar archivo
                            </button>
                            <input id="fileInput" type="file" accept=".pdf" class="hidden" @change="handleFileChange"
                                multiple />
                        </label>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
import PdfViewer from './PdfViewer.vue';
import { getCookie } from '../../helpers/cookies';
import axios from 'axios';

export default {
    name: 'Step2Document',
    components: {
        PdfViewer
    },
    props: {
        signersData: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            campains: [], // Move campains to data
            showInfoMessage: false,
            showInfoMessage2: false,
            showInfoMessageValidacion: false,
            showInfoMessageHojas: false,
            documentId: '',
            base64Doc: null,
            send_dni: false,
            validate_dni: false,
            todas_las_hojas: false,
            fileNames: [],
            isFileUploaded: false,
            isPdfLoaded: false,
            loading: false,
            signersCoords: [],
            loadingStep: true,
            showPopup: false,
            templateName: '',
            signersDataCopy: [],
            selectedCampaignId: 1,
            signersDataToReturn: [],
            limitTemplateSave: false,
            confirmationPopup: false,
        };
    },
    created() {
        this.getCampains(); // Fetch campaigns when component is created
    },
    methods: {
        changeCampaign() {
            const campain = this.campains.find(campain => campain.name === this.selectedCampainName);

            this.selectedCampaignId = campain.id;
            
            this.send_dni = campain.photos;
            this.validate_dni = campain.dni_validation;
            this.todas_las_hojas = campain.all_pages;

            if (!campain.positions) { // Checks for null, undefined, or missing key
                for (const element of this.signersDataCopy) {
                    element.position_x = null;
                    element.position_y = null;
                    element.page = null;
                }
                return;
            }


            Object.entries(campain.positions).forEach(([, element], index) => {
                if (this.signersDataCopy[index]) { // Ensure the index exists in the copied data
                    this.signersDataCopy[index].position_x = element.x;
                    this.signersDataCopy[index].position_y = element.y;
                    this.signersDataCopy[index].page = element.page;

                    this.signersDataToReturn[index].position_x = element.x;
                    this.signersDataToReturn[index].position_y = element.y;
                    this.signersDataToReturn[index].page = element.page;
                }
            });


        },
        openPopup() {
            this.showPopup = true;
        },
        closePopup() {
            this.showPopup = false;
            this.templateName = ''; // Reset the input field
        },
        async saveTemplate() {
            if (this.templateName.trim() === "") {
                alert("Por favor, ingrese un nombre para la plantilla.");
                return;
            }

            const signers = { "signer_1": { "x": null, "y": null, "page": null }, "signer_2": { "x": null, "y": null, "page": null }, "signer_3": { "x": null, "y": null, "page": null }, "signer_4": { "x": null, "y": null, "page": null }, "signer_5": { "x": null, "y": null, "page": null }, "signer_6": { "x": null, "y": null, "page": null } }

            for (let i = 0; i < this.signersDataToReturn.length; i++) {
                signers[`signer_${i + 1}`] = {
                    x: this.signersDataToReturn[i].position_x,
                    y: this.signersDataToReturn[i].position_y,
                    page: this.signersDataToReturn[i].page
                }
            }

            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': getCookie('token')
            };
            const data = {
                name: this.templateName,
                positions: signers,
                dni_validation: this.validate_dni,
                photos: this.send_dni,
                all_pages: this.todas_las_hojas
            };

            await axios.post(`${process.env.VUE_APP_API_URL}/api/saveTemplate`, data, { headers });
            this.limitTemplateSave = true;

            // Simulating save action

            this.confirmationPopup = true;

            setTimeout(() => {
                this.confirmationPopup = false;
            }, 2000);

            this.closePopup();
        },
        handleNextStep() {
            this.$emit('next-step', this.$data);
        },
        onCoords(data) {
            this.signersCoords = data;
            this.signersDataToReturn = data;
            // this.$emit('update-coords', data);
        },
        async getCampains() {
            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': getCookie('token')
            };
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/campains`, { headers });
            this.campains = response.data;
            if (this.campains.length > 0) {
                this.selectedCampainName = this.campains[1].name;
                this.selectedCampaignId = this.campains[1].id;
                if (this.selectedCampaignId !== 1) {
                    this.isPdfLoaded = true;
                }
                // remove campain index 0 (we dont want siger to choose the position)
                this.campains.shift();

            }
            this.signersDataCopy = [...this.signersData];
            this.signersDataToReturn = [...this.signersData];

            if (!this.campains[0].positions) {
                for (const element of this.signersDataCopy) {
                    element.position_x = null;
                    element.position_y = null;
                    element.page = null;
                }
            }

            this.loadingStep = false;
        },
        handleDrop(event) {
            const files = event.dataTransfer.files;
            const file = files[0];
            this.handleFile(file);
            this.documentId = this.removeFileExtension(file.name);
        },
        openFileInput() { // Subir archivos mediante el botón
            document.getElementById('fileInput').click();
        },
        handleFileChange(event) {
            const file = event.target.files[0]; // Obtener el primer archivo del evento
            this.handleFile(file);
            if (!this.documentId) { // Si no se ingresó un nombre de documento, se autocompleta
                this.documentId = this.removeFileExtension(file.name);
            }
        },
        removeFileExtension(filename) {
            return filename.replace(/\.[^/.]+$/, ""); // Eliminar la extensión del nombre del archivo
        },
        handleFile(file) {
            this.loading = true;
            this.documentSelected = false;

            if (this.isValidFileType(file)) {
                let reader = new FileReader();

                reader.onload = () => {

                    setTimeout(() => {
                        this.base64Doc = reader.result.split(',')[1]; // Extracting base64 data from result
                        this.fileNames = [file.name]; // Reemplazar los nombres de archivo con el nuevo archivo
                        this.loading = false;
                        this.documentSelected = true;
                        this.isFileUploaded = true;
                    }, 1000); // Tiempo de simulación de carga
                };
                reader.readAsDataURL(file);
            } else {
                this.loading = false;
            }
        },
        isValidFileType(file) {
            // TODO: , '.docx', '.jpg', '.jpeg', '.png'
            const allowedExtensions = ['.pdf'];
            const fileType = '.' + file.name.split('.').pop();
            return allowedExtensions.includes(fileType.toLowerCase());
        },
        onPdfLoaded() {
            this.isPdfLoaded = true; // Set to true when PDF is loaded
        },
        onPdfNotLoaded() {
            this.isPdfLoaded = false; // Set to false when PDF is not loaded
        },
        removeFile(index) {
            this.fileNames.splice(index, 1);
            this.isFileUploaded = false;
            this.base64Doc = null;
            if (this.fileNames.length === 0) {
                this.showPdfViewer = false; // Hide the PDF viewer if no files are left
            }
        },
    }
}
</script>

<style scoped>
/* Spinner styles */
.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full screen height */
}

/* Spinner styles */
.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>