<template>
    <div class="flex justify-end p-6">
        <div class="flex items-center space-x-4 mr-6">
            <button class="text-gray-500 font-bold flex items-center space-x-2 cursor-not-allowed">
                <i class="fas fa-arrow-left mr-2 text-white bg-gray-500 rounded-full p-1"></i>Volver
            </button>
            <button @click="nextStep" :class="[
                'font-bold flex items-center space-x-2',
                isAllSignersValid
                    ? 'text-blue-500 hover:text-blue-400'
                    : 'text-gray-500 cursor-not-allowed'
            ]" :disabled="!isAllSignersValid">
                Continuar
                <i :class="[
                    'fas fa-arrow-right ml-2 rounded-full p-1',
                    isAllSignersValid
                        ? 'text-white bg-blue-500 hover:bg-blue-400'
                        : 'text-white bg-gray-500'
                ]"></i>
            </button>
        </div>
    </div>

    <!-- MENSAJE DE ERROR -->
    <div v-if="signerError"
        class="m-6 mb-0 text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center"
        style="width: 460px;">
        <span class="ml-2 font-bold">{{ signerErrorMessage }}</span>
        <button @click="$emit('update:signerError', false)"><i
                class="fas fa-times text-lg cursor-pointer text-gray-800 hover:bg-red-300 px-2 rounded-sm"></i></button>
    </div>

    <!-- Campos para los firmantes -->
    <div class="m-6">
        <div v-for="(signer, index) in signers" :key="index" class="mb-12">
            <div class="flex items-center">
                <input type="text" v-model="signer.name"
                    :placeholder="index === 0 ? 'Nombre firmante' : 'Nombre firmante ' + (index + 1)"
                    class="border-gray-300 border-b-2 w-56 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 mr-12">
                <input type="text" v-model="signer.lastName"
                    :placeholder="index === 0 ? 'Apellido firmante' : 'Apellido firmante ' + (index + 1)"
                    class="border-gray-300 border-b-2 w-56 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 mr-12">
                <input type="text" v-model="signer.dni"
                    :placeholder="index === 0 ? 'DNI / CI firmante' : 'DNI / CI firmante ' + (index + 1)"
                    class="border-gray-300 border-b-2 w-56 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800"
                    @input="sanitizeDNI(index)">
            </div>
            <div class="flex items-center">

                <template v-if="signer.contact === 'wpp'">
                    <select v-model="signer.areaCode" placeholder="Área"
                        class="border-gray-300 border-b-2 w-35 focus:outline-none p-2 pl-0 mr-6 placeholder-gray-800">
                        <option value="549" selected>🇦🇷 +549</option>
                        <option value="34">🇪🇸 +34</option>
                        <option value="56">🇨🇱 +56</option>
                        <option value="598">🇺🇾 +598</option>
                        <option value="521">🇲🇽 +521</option>
                        <option value="593">🇪🇨 +593</option>
                        <option value="591">🇧🇴 +591</option>
                        <option value="57">🇨🇴 +57</option>
                        <option value="51">🇵🇪 +51</option>
                        <option value="1">🇺🇸 +1</option>
                        <option value="502">🇩🇴 +502</option>
                        <option value="595">🇵🇾 +595</option>
                    </select>
                    <input type="text" v-model="signer.phoneNumber" placeholder="Teléfono"
                        @input="sanitizePhoneNumber(index)"
                        class="border-gray-300 border-b-2 w-56 focus:outline-none p-2 placeholder-gray-800">
                </template>
                <div v-show="signers.length > 1" class="flex flex-col">
                    <button @click="removeSigner(index)" class="flex items-center ml-8">
                        <i class="fas fa-minus-circle text-red-500 mr-2 text-xl"></i>
                        <span class="text-gray-500 hover:text-gray-400">Eliminar firmante</span>
                    </button>
                </div>
            </div>
        </div>

        <!-- MENSAJE DE ERROR -->
        <div v-if="maxSignersError"
            class="mb-12 text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center"
            style="width: 460px;">
            <span class="ml-2 font-bold">{{ maxSignersErrorMessage }}</span>
            <button @click="$emit('update:maxSignersError', false)"><i
                    class="fas fa-times text-lg cursor-pointer text-gray-800 hover:bg-red-300 px-2 rounded-sm"></i></button>
        </div>

        <button @click="addSigner" class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-bold rounded-full"><i
                class="fas fa-search-plus mr-2"></i>Agregar firmante</button>
    </div>
</template>


<script>
export default {
    name: 'Step1Signers',
    props: {
        signers: {
            type: Array,
            required: true
        },
        signerError: {
            type: Boolean,
            default: false
        },
        signerErrorMessage: {
            type: String,
            default: ''
        },
        maxSignersError: {
            type: Boolean,
            default: false
        },
        maxSignersErrorMessage: {
            type: String,
            default: ''
        }
    },
    computed: {
        isAllSignersValid() {
            return this.signers.length > 0 && this.signers.every(signer =>
                signer.name.trim() !== '' &&
                signer.lastName.trim() !== '' &&
                signer.dni.trim() !== '' &&
                signer.areaCode.trim() !== '' &&
                signer.phoneNumber.trim() !== ''
            );
        }
    },
    methods: {
        nextStep() {
            if (this.isAllSignersValid) {
                this.$emit('next-step', { signers: this.signers });
            }
        },
        addSigner() {
            if (this.signers.length < 6) {
                // Instead of mutating the prop directly, emit an event
                this.$emit('update-signers', [...this.signers, { name: '', lastName: '', dni: '', areaCode: '', phoneNumber: '', contact: 'wpp', position_x: null, position_y: null, page: null, signerNumber: this.signers.length }]);
            } else {
                this.$emit('update:maxSignersError', true);
            }
        },
        removeSigner(index) {
            if (this.signers.length > 1) {
                // Emit an event with the new array after removing the signer
                this.$emit('update-signers', this.signers.filter((_, i) => i !== index));
            } else {
                // If there's only one signer, reset it instead of removing
                this.$emit('update-signers', [{ name: '', lastName: '', dni: '', areaCode: '', phoneNumber: '', contact: 'wpp', position_x: null, position_y: null, page: null, signerNumber: 0 }]);
            }
        },
        sanitizeDNI(index) {
            const signer = { ...this.signers[index] };

            // Extract and sanitize DNI
            const letters = [];
            const numbers = [];

            for (const char of signer.dni) {
                if (/[a-zA-Z]/.test(char) && letters.length < 3) {
                    letters.push(char);
                } else if (/\d/.test(char)) {
                    numbers.push(char);
                }
            }

            // Reconstruct DNI while preserving order
            const sanitizedDNI = signer.dni
                .split('')
                .map(char => {
                    if (/[a-zA-Z]/.test(char) && letters.length) {
                        return letters.shift();
                    } else if (/\d/.test(char) && numbers.length) {
                        return numbers.shift();
                    }
                    return '';
                })
                .join('');

            signer.dni = sanitizedDNI;

            this.$emit('update-signer', { index, ...signer });
        },
        sanitizePhoneNumber(index) {
            const signer = { ...this.signers[index] };
            signer.phoneNumber = signer.phoneNumber.replace(/\D/g, ""); // Remove everything that's not a digit
            this.$emit('update-signer', { index, ...signer });
        },

    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
