<template>
    <main class="flex p-4 pt-0 lg:ml-56 bg-gray-100">
        <div class="rounded-2xl w-full overflow-x-auto bg-white">
            <!-- Barra de pasos -->
            <div class="flex justify-between items-center border-b mx-6 border-gray-300 px-20 py-3 text-sm">
                <div class="flex items-center">
                    <p :class="{ 'text-blue-500 font-semibold': currentStep === 1, 'text-gray-400': currentStep !== 1 }"
                        class="text-blue-500">1. Cargar firmantes</p>
                </div>
                <div class="flex items-center">
                    <p :class="{ 'text-blue-500 font-semibold': currentStep === 2, 'text-gray-400': currentStep !== 2 }"
                        class="text-blue-500">2. Cargar documento</p>
                </div>
                <div class="flex items-center">
                    <p :class="{ 'text-blue-500 font-semibold': currentStep === 3, 'text-gray-400': currentStep !== 3 }"
                        class="text-blue-500">3. Enviar</p>
                </div>
            </div>

            <!-- Paso 1: Cargar firmantes -->
            <div v-if="currentStep === 1">
                <div class="flex justify-between item-center p-6">

                    <!-- Add Download Example Button -->
                    <div class="flex">
                        <button @click="downloadExampleCSV"
                            class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 font-semibold rounded-full">
                            <i class="fas fa-download mr-2"></i>Descargar Ejemplo
                        </button>
                    </div>


                    <div class="flex items-center space-x-4 mr-6">
                        <button
                            class="text-gray-500 hover:text-gray-400 font-bold flex items-center space-x-2 cursor-not-allowed">
                            <i
                                class="fas fa-arrow-left mr-2 text-white bg-gray-500 hover:bg-gray-400 rounded-full p-1"></i>Volver</button>
                        <button @click="nextStep" class="text-blue-500 hover:text-blue-400 font-bold">Continuar<i
                                class="fas fa-arrow-right ml-2 text-white bg-blue-500 hover:bg-blue-400 rounded-full p-1"></i></button>
                    </div>
                </div>

                <!-- MENSAJE DE ERROR -->
                <div v-if="signerError"
                    class="m-6 mb-0 text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center"
                    style="width: 460px;">
                    <span class="ml-2 font-bold">{{ errorMessage }}</span>
                    <button @click="signerError = false"><i
                            class="fas fa-times text-lg cursor-pointer text-gray-800 hover:bg-red-300 px-2 rounded-sm"></i></button>
                </div>

                <!-- CSV input -->

                <!-- Box 1: Upload Prompt -->
                <div v-if="!isCsvUploaded" class="mb-6 flex justify-center items-center w-full h-full top-0 left-0">
                    <label for="fileInputDF"
                        class="w-80 h-52 flex flex-col items-center text-center px-6 py-6 bg-white rounded-lg border-4 border-dashed border-green-400 cursor-pointer hover:bg-green-50"
                        @dragover.prevent @drop.prevent="handleDropCSV">
                        <i class="fas fa-file-excel text-gray-400 fa-4x mb-4" style="color: green;"></i>
                        <p class="text-gray-500">Arrastra y suelta el CSV o XLSX aquí o:</p>
                        <button @click="openFileInputCSV()"
                            class="bg-green-500 hover:bg-green-600 text-white px-4 py-1 font-semibold rounded-full mt-2">
                            <i class="fas fa-search-plus mr-2"></i>Seleccionar archivo
                        </button>
                        <input id="fileInputDF" type="file" accept=".csv, .xlsx" class="hidden"
                            @change="handleFileChangeCSV" multiple ref="fileInputDF" />
                    </label>
                </div>

                <!-- Box 2: Uploaded File Info -->
                <div>
                    <div v-if="isCsvUploaded" class="mb-6 flex justify-center items-center w-full h-full top-0 left-0">
                        <div
                            class="inset-0 flex flex-col items-center justify-center w-80 h-52 bg-green-50 border border-green-400 rounded-lg p-4">
                            <p class="text-green-600 font-semibold mb-2">Archivo seleccionado:</p>
                            <p class="text-gray-800 bg-white px-4 py-2 rounded-lg border border-gray-300">{{ csvName }}
                            </p>
                            <button @click="removeUploadedFile"
                                class="bg-red-500 hover:bg-red-600 text-white px-4 py-2 mt-4 rounded-full">
                                Quitar archivo
                            </button>
                        </div>

                        <!-- Status Text -->
                        <div class="ml-6 text-gray-600 text-lg font-medium">
                            <p>{{ okDocuments }}/{{ counterDocuments }} documentos cargados correctamente.</p>
                        </div>
                    </div>


                </div>


                <!-- Box 3: Display Loaded Data -->
                <div v-if="isCsvUploaded && loadedDataLowerCase.length"
                    class="mt-6 overflow-auto max-h-96 border border-gray-200 rounded-lg p-4 small-font">
                    <h3 class="text-green-600 font-bold mb-4">Datos Cargados:</h3>
                    <div class="flex flex-wrap gap-4">
                        <div v-for="(data, index) in loadedDataLowerCase" :key="index"
                            :class="data.status ? 'bg-green-100 border-green-400' : 'bg-red-100 border-red-400'"
                            class="p-4 border rounded-lg w-64">
                            <p>
                                <strong>ID Custom: </strong>
                                <span v-if="data.id">{{ data.id }}</span>
                                <span v-else></span>
                            </p>
                            <p>
                                <strong>Nombre y Apellido: </strong>
                                <span v-if="data['nombre y apellido']">{{ data['nombre y apellido'] }}</span>
                                <span v-else class="text-red-500 font-bold">Falta nombre y apellido</span>
                            </p>
                            <p>
                                <strong>WhatsApp: </strong>
                                <span v-if="data.whatsapp">{{ data.whatsapp }}</span>
                                <span v-else class="text-red-500 font-bold">Falta WhatsApp</span>
                            </p>
                            <p>
                                <strong>DNI/CI: </strong>
                                <span v-if="data.dni">{{ data.dni }}</span>
                                <span v-else class="text-red-500 font-bold">Falta DNI</span>
                            </p>
                        </div>
                    </div>
                </div>







            </div>

            <!-- Paso 2: Cargar documento -->
            <div v-if="currentStep === 2" class="container mx-auto p-6 space-y-6">

                <!-- Document ID Input and Buttons -->
                <div class="flex items-center justify-between">
                    <!-- Document ID Input -->
                    <div class="ml-6 flex items-center text-lg">
                        <input type="text" v-model="documentId"
                            class="border-b border-gray-300 mr-4 placeholder-black focus:outline-none w-64"
                            placeholder="Id / Nombre del documento" />
                        <i class="fas fa-edit text-gray-400"></i>
                    </div>

                    <!-- Navigation Buttons -->
                    <div class="flex items-center space-x-4 mr-6">
                        <button @click="previousStep"
                            class="text-blue-500 hover:text-blue-400 font-bold flex items-center space-x-2"
                            :disabled="loading">
                            <i class="fas fa-arrow-left text-white bg-blue-500 hover:bg-blue-400 rounded-full p-1"></i>
                            <span>Volver</span>
                        </button>
                        <button @click="nextStep" :class="[
                            'font-bold flex items-center space-x-2',
                            { 'text-blue-500 hover:text-blue-400': isFileUploaded && isPdfLoaded && !loading, 'text-gray-400 cursor-not-allowed': loading || !isFileUploaded || !isPdfLoaded }
                        ]" :disabled="loading || !isFileUploaded || !isPdfLoaded">
                            <span>Continuar</span>
                            <i :class="{
                                'fas fa-arrow-right ml-2 rounded-full p-1': true,
                                'text-white bg-blue-500 hover:bg-blue-400': isFileUploaded && isPdfLoaded && !loading,
                                'bg-gray-500': !isFileUploaded || !isPdfLoaded || loading
                            }"></i>
                        </button>

                    </div>
                </div>

                <!-- Document ID Error Message -->
                <div v-if="documentIdError"
                    class="m-6 mb-0 text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center w-96">
                    <span class="font-bold">{{ documentIdErrorMessage }}</span>
                    <button @click="removeErrorMessage('documentIdError')"
                        class="text-gray-800 hover:bg-red-300 px-2 rounded-sm">
                        <i class="fas fa-times text-lg cursor-pointer"></i>
                    </button>
                </div>

                <div>
                    <!-- Signature Position Selection -->
                    <ul class="ml-6 space-y-4 space-x-2">
                        <li class="flex items-center mb-2">
                            <p>Configuración de firma:</p>
                            <div class="flex items-center">
                                <select v-model="selectedCampainName"
                                    class="border-gray-300 border-b-2 w-35 focus:outline-none ml-2 placeholder-gray-800">
                                    <option v-for="item in campains" :key="item.id" :value="item.name">{{ item.name }}
                                    </option>
                                </select>
                                <i :title="'Si tienes documentos recurrentes con otra ubicación específica de firma, \nsimplemente envía un correo a soporte@wapifirma.com con el documento en PDF \ny la posición exacta donde te gustaría que coloquemos la firma para agregarla a tu desplegable.'"
                                    class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer ml-2"></i>
                            </div>
                        </li>
                    </ul>

                    <!-- Pedir fotos -->
                    <div class="flex items-center space-x-2 ml-6 mb-1">
                        <input type="checkbox" v-model="send_dni" class="cursor-pointer h-4 w-4">
                        <p>Prueba de Vida (foto selfie + DNI/CI)</p>
                        <i :title="'El firmante deberá tomar una foto del documento de identidad + foto selfie para firmar (Wapi no valida las fotos). \nLas fotos quedarán en el informe de firmas.'"
                            class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer ml-2"></i>
                    </div>

                    <!-- Validar DNI/CI Checkbox -->
                    <div v-if="selectedCampaignId === 1" class="flex items-center space-x-2 ml-6 mb-1">
                        <input type="checkbox" v-model="validate_dni" class="cursor-pointer h-4 w-4">
                        <p>Validar DNI/CI</p>
                        <i :title="'Valida las fotos.'"
                            class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer ml-2"></i>
                    </div>


                    <!-- Firmar en todas las hojas -->
                    <div class="flex items-center space-x-2 ml-6">
                        <input type="checkbox" v-model="todas_las_hojas" class="cursor-pointer h-4 w-4">
                        <p>Firma en todas las hojas</p>
                        <i :title="'Se aplica la firma en todas las hojas.'"
                            class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer"></i>
                    </div>


                    <!-- Container for Files and PDF Viewer -->
                    <div class="m-6 flex space-x-6">
                        <!-- Display Uploaded Files -->
                        <div v-if="fileNames.length > 0" class="space-y-2">
                            <div v-for="(name, index) in fileNames" :key="index"
                                class="bg-blue-100 p-2 rounded-md text-center flex justify-between items-center w-96">
                                <span class="text-black">{{ index + 1 }}. {{ name }}</span>
                                <button @click="removeFile(index)"
                                    class="text-gray-800 hover:bg-blue-300 px-2 rounded-sm">
                                    <i class="fas fa-times text-lg cursor-pointer"></i>
                                </button>
                            </div>
                        </div>

                        <!-- PDF Viewer without horizontal scroll -->
                        <div v-if="base64Doc && selectedCampaignId === 1" class="flex overflow-hidden p-7">
                            <PdfViewer :pdfData="base64Doc" :signers="signersData" @signers-load="onPdfLoaded"
                                @signers-not-load="onPdfNotLoaded" @coords="onCoords" />
                        </div>
                    </div>

                </div>

                <!-- Loading Message -->
                <div v-if="loading" class="m-6 text-center">
                    <div class="inline-block w-24 h-1 bg-blue-500 rounded-full animate-pulse"></div>
                    <p class="mt-2 text-gray-500">Cargando...</p>
                </div>

                <!-- Error Message -->
                <div v-if="errorMessage"
                    class="m-6 text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center w-96">
                    <span class="font-bold">{{ errorMessage }}</span>
                    <button @click="removeErrorMessage('errorMessage')"
                        class="text-gray-800 hover:bg-red-300 px-2 rounded-sm">
                        <i class="fas fa-times text-lg cursor-pointer"></i>
                    </button>
                </div>

                <!-- File Upload -->
                <div v-if="!isFileUploaded" class="m-6 flex justify-center">
                    <label for="fileInput"
                        class="w-96 h-60 flex flex-col items-center text-center px-4 py-6 hover:bg-blue-100 cursor-pointer border-4 border-blue-400 border-dashed"
                        @dragover.prevent @drop.prevent="handleDrop">
                        <i class="fas fa-file-word text-gray-400 fa-4x mb-4" style="color: #3B82F6;"></i>
                        <p class="text-gray-400">Arrastrar y soltar el documento .DOCX aquí o:</p>
                        <button @click="openFileInput"
                            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-bold rounded-full mt-2">
                            <i class="fas fa-search-plus mr-2"></i>Seleccionar archivo
                        </button>
                        <input id="fileInput" type="file" accept=".docx" class="hidden" @change="handleFileChange"
                            multiple />
                    </label>
                </div>



            </div>


            <!-- Paso 3: Enviar -->
            <div v-if="currentStep === 3">
                <div class="m-6">
                    <div class="flex justify-center">


                        <div v-if="isCsvUploaded && loadedDataTrue.length"
                            class="mt-6 overflow-auto max-h-96 border border-gray-200 rounded-lg p-4">
                            <h3 class="text-green-600 font-bold mb-4">Datos Cargados:</h3>
                            <div class="flex flex-wrap gap-4">
                                <div v-for="(data, index) in loadedDataTrue" :key="index"
                                    :class="data.status ? 'bg-green-100 border-green-400' : 'bg-red-100 border-red-400'"
                                    class="p-4 border rounded-lg w-64">
                                    <p>
                                        <strong>ID Custom: </strong>
                                        <span v-if="data.id">{{ data.id }}</span>
                                        <span v-else></span>
                                    </p>
                                    <p>
                                        <strong>Nombre y Apellido: </strong>
                                        <span v-if="data['nombre y apellido']">{{ data['nombre y apellido'] }}</span>
                                        <span v-else class="text-red-500 font-bold">Falta nombre y apellido</span>
                                    </p>
                                    <p>
                                        <strong>WhatsApp: </strong>
                                        <span v-if="data.whatsapp">{{ data.whatsapp }}</span>
                                        <span v-else class="text-red-500 font-bold">Falta WhatsApp</span>
                                    </p>
                                    <p>
                                        <strong>DNI/CI: </strong>
                                        <span v-if="data.dni">{{ data.dni }}</span>
                                        <span v-else class="text-red-500 font-bold">Falta DNI</span>
                                    </p>
                                </div>
                            </div>
                        </div>




                    </div>
                    <div class="flex justify-center mt-7">
                        <router-link to="/requests?enviado=true">
                            <button @click="sendDocuments"
                                class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-bold rounded-full">
                                <i class="fas fa-file mr-2"></i>Enviar documentos
                            </button>
                        </router-link>

                    </div>
                </div>
            </div>
        </div>
    </main>

    <ModalPurchaseComponent v-if="showLimitExceededModal" :show="showLimitExceededModal"
        @close="showLimitExceededModal = false" />
</template>

<script>
import ModalPurchaseComponent from '../components/ModalPurchaseComponent.vue';
import axios from 'axios';
import { getCookie } from '../helpers/cookies';
import PdfViewer from './create-document/PdfViewer.vue';



export default {
    emits: ['login', 'logout'],
    props: ['user'],
    components: {
        ModalPurchaseComponent,
        PdfViewer,
    },
    data() {
        // TODO: dar errores mas especificos dependiendo que pasa, ejemplo signerErrorDuplicate esta sin usarse
        return {
            currentStep: 1,
            loading: false,
            signersData: [{
                signerNumber: 0,
                name: 'Firma',
                position_x: null,
                position_y: null,
                page: null,
                dniValidation: false,
                pedirFoto: false,
                flowId: 1,
            }
            ],
            isCsvUploaded: false,
            loadedData: [],
            loadedDataTrue: [],
            loadedDataLowerCase: [],
            base64Csv: null,
            dfType: '',
            selectedCampainName: '',
            base64Doc: null,
            docType: '',
            docxB64: '',
            okDocuments: 0,
            counterDocuments: 0,
            previousPage: 0,
            signersDataCopy: [{
                signerNumber: 0,
                name: 'Firma',
                position_x: null,
                position_y: null,
                page: null,
                dniValidation: false,
                pedirFoto: false,
                flowId: 1,
            }
            ],



            fileNames: [],
            errorMessage: '',
            documentSelected: false,
            signers: [{ name: '', lastName: '', dni: '', areaCode: '', phoneNumber: '', contact: 'wpp' }],
            signerError: false,
            signerErrorMessage: 'Por favor, completa todos los datos de los firmantes.',
            signerErrorDuplicate: 'No puedes agregar dos firmantes con el mismo número de teléfono.',
            maxSignersError: false,
            maxSignersErrorMessage: 'Se ha alcanzado el límite máximo de firmantes (6).',
            documentSigned: false,
            documentId: '',
            automaticPositionChecked: false,
            photoIdChecked: false,
            showInfoMessage: null,
            showInfoMessage2: null,
            showInfoMessageValidacion: null,
            copiedLinks: [],

            showLimitExceededModal: false,
            sendWithWapiIABodys: [],
            todasLasSolicitudes: {
                urls: [],
            },
            hasChanges: false,
            campains: [],

            selectedCampaignId: 1,
            send_dni: false,
            validate_dni: false,
            todas_las_hojas: false,
            flow_id: 1,
            isFileUploaded: false, // New property to track file upload status
            isPdfLoaded: false,
            coords: [],
        };
    },
    watch: {
        // Watcher to detect changes in the inputs
        todasLasSolicitudes: {
            handler() {
                if (this.currentStep === 3) {
                    this.hasChanges = true;
                }
            },
            deep: true,
        },
        selectedCampainName(newName) {
            this.updateSelectedCampain(newName);
            if (this.validate_dni) {
                this.selectedCampaign.dni_validation = this.validate_dni;
            }

        },
        validate_dni(newVal) {
            this.validate_dni = newVal;
            this.signersDataCopy[0].dniValidation = newVal;
        },
        send_dni(newVal) {
            this.send_dni = newVal;
            this.signersDataCopy[0].pedirFoto = newVal;
        },
        // TODO: deberia tener cuidado porque si marca una hoja (2 por ejemplo) y luego desmarca, se queda en la hoja 1
        todas_las_hojas(newVal) {
            this.todas_las_hojas = newVal;
            if (newVal) {
                this.signersDataCopy[0].page = 0;
            } else {
                this.signersDataCopy[0].page = this.previousPage;
            }
        }
    },
    methods: {
        async nextStep() {
            if (this.loading) return; // Prevent multiple clicks

            this.loading = true;

            try {
                if (this.loadedData.length === 0) {
                    this.errorMessage = 'Cargue un archivo CSV o XLSX.';
                    this.signerError = true;
                    return;
                }
                if (this.currentStep === 1) {
                    await this.getCampains();
                }
                // Verificar si no se ha seleccionado ningún documento
                if (this.currentStep === 2 && this.fileNames.length === 0) {
                    this.errorMessage = 'Seleccione un documento.';
                    return;
                }

                // Crear doc y mostrar popup
                if (this.currentStep === 2) {
                    this.loading = true;
                    this.loadedDataTrue = this.loadedDataLowerCase.filter(item => item.status);
                }


                // Si pasa todas las validaciones, avanzar al siguiente paso
                if (!this.showLimitExceededModal) {
                    this.errorMessage = '';
                    this.currentStep++;
                }
            } finally {
                this.loading = false;
            }
        },
        downloadExampleCSV() {

            axios({
                url: 'https://wapi-doc.s3.us-east-2.amazonaws.com/Wapi-Firma-Carga-Masiva.csv',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Wapi-Firma-Carga-Masiva.csv');
                document.body.appendChild(link);
                link.click();
            });
        },
        onCoords(signers) {
            this.coords = signers;
            this.coords.forEach(element => {
                for (const signer of this.signersDataCopy) {
                    signer.position_y = element.position_y;
                    signer.position_x = element.position_x;
                    signer.page = element.page;
                    this.previousPage = element.page;
                }
            });

        },
        onPdfLoaded() {
            this.isPdfLoaded = true; // Set to true when PDF is loaded
        },
        onPdfNotLoaded() {
            this.isPdfLoaded = false; // Set to false when PDF is not loaded
        },
        removeFile(index) {
            this.fileNames.splice(index, 1);
            this.isFileUploaded = false;
            this.base64Doc = null;
            if (this.fileNames.length === 0) {
                this.showPdfViewer = false; // Hide the PDF viewer if no files are left
            }
        },
        logout() {
            this.$emit('logout');
        },
        removeUploadedFile() {
            this.isCsvUploaded = false;
            this.csvName = '';
            this.readyToSend = false;
            this.loadedData = [];
            this.loadedDataLowerCase = [];
        },
        allKeysLowerCase(obj) {
            return Object.keys(obj).reduce((acc, key) => {
                acc[key.toLowerCase()] = obj[key];
                return acc;
            }, {});
        },
        async loadCsvData() {
            this.loadedData = [];
            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': getCookie('token')
            };
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/parseCsv`, { df: this.base64Csv, dfType: this.dfType }, { headers });
            this.loadedData = response.data;
            this.counterDocuments = this.loadedData.length;
            this.okDocuments = this.loadedData.filter(item => item.status).length;
            this.loadedDataLowerCase = this.loadedData.map(this.allKeysLowerCase);
        },
        readFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    try {
                        const base64 = reader.result.split(',')[1]; // Extract base64 data
                        resolve(base64);
                    } catch (err) {
                        reject(err);
                    }
                };

                reader.onerror = () => {
                    reject(reader.error);
                };

                reader.readAsDataURL(file);
            });
        },
        async handleFileCSV(file) {
            this.errorMessage = '';

            const fileType = file.name.split('.').pop().toLowerCase();
            if (fileType === 'csv' || fileType === 'xlsx') {
                this.base64Csv = await this.readFileAsBase64(file);
                this.csvName = file.name;
                this.isCsvUploaded = true;
                this.dfType = fileType;
                this.signerError = false;
            } else {
                this.errorMessage = 'Solo archivos CSV o XLSX';
            }
        },
        async handleFileChangeCSV(event) {
            this.loading = true;

            const file = event.target.files[0];
            await this.handleFileCSV(file);
            await this.loadCsvData();
            this.loading = false;

        },
        openFileInputCSV() {
            this.$refs.fileInputDF.click();
        },
        previousStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },
        async convertDocToPdf(file) {
            // Call the API to convert the DOCX to PDF
            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': getCookie('token')
            };

            let docb64 = await this.readFileAsBase64(file);
            this.docxB64 = docb64;
            const body = {
                doc: docb64
            };
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/docxToPdf`, body, { headers });

                if (response.status === 200) {
                    this.base64Doc = response.data.pdf;
                } else {
                    console.error('Conversion failed');
                }
            } catch (error) {
                console.error('Error during conversion:', error);
            }
        },
        async handleFile(file) {
            this.loading = true;
            this.errorMessage = '';
            this.documentSelected = false;

            const fileType = file.name.split('.').pop().toLowerCase();
            this.fileNames = [file.name];
            if (fileType === 'docx') {
                this.docType = fileType;
                await this.convertDocToPdf(file); // Convert DOCX to PDF
                this.loading = false;
                this.isFileUploaded = true;
            } else {
                this.errorMessage = 'Solo archivos DOCX';
                this.loading = false;
            }
        },
        removeFileExtension(filename) {
            return filename.replace(/\.[^/.]+$/, ""); // Eliminar la extensión del nombre del archivo
        },
        handleFileChange(event) {
            const file = event.target.files[0]; // Obtener el primer archivo del evento
            this.handleFile(file);
            if (!this.documentId) { // Si no se ingresó un nombre de documento, se autocompleta
                this.documentId = this.removeFileExtension(file.name);
            }
        },
        openFileInput() { // Subir archivos mediante el botón
            document.getElementById('fileInput').click();
        },
        updateSelectedCampain(selectedName) {
            this.selectedCampaign = this.campains.find(item => item.name === selectedName) || null;
            this.selectedCampaignId = this.selectedCampaign?.id ?? 0;
            if (this.selectedCampaignId === 1) {
                this.isPdfLoaded = false;
                this.validate_dni = false;
                this.send_dni = false;
            } else {
                this.isPdfLoaded = true;
                this.validate_dni = false;
                this.send_dni = false;
            }

        },
        async getCampains() {
            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': getCookie('token')
            };
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/campains`, { headers });
            this.campains = response.data;
            if (this.campains.length > 0) {
                this.selectedCampainName = this.campains[1].name;
                this.selectedCampaignId = this.campains[1].id;
                if (this.selectedCampaignId !== 1) {
                    this.isPdfLoaded = true;
                }
                // remove campain index 0 (we dont want siger to choose the position)
                this.campains.shift();

            }
            // delete all campains except the one with id 1
            // this.campains = this.campains.filter(item => item.id === 1);

            if (!this.campains[0].positions) {
                for (const element of this.signersData) {
                    element.position_x = null;
                    element.position_y = null;
                    element.page = null;
                }
            }

        },
        async handleDropCSV(event) {
            this.loading = true;
            const files = event.dataTransfer.files;
            const file = files[0];
            await this.handleFileCSV(file);
            await this.loadCsvData();
            this.loading = false;
        },
        handleDrop(event) {
            const files = event.dataTransfer.files;
            const file = files[0]; // Obtener el primer archivo arrastrado
            this.handleFile(file);
            this.documentId = this.removeFileExtension(file.name);
        },
        async sendDocuments() {
            if (this.signersDataCopy[0].pedirFoto) {
                this.signersDataCopy[0].flowId = 2;
                if (this.signersDataCopy[0].dniValidation) {
                    this.signersDataCopy[0].flowId = 4;
                }
            } else if (this.signersDataCopy[0].dniValidation) {
                this.signersDataCopy[0].flowId = 3;
            }

            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': getCookie('token')
            };

            let df = []
            for (let element of this.loadedData) {
                if (element.status) {
                    df.push(element);
                }
            }

            const body = {
                doc: this.docxB64,
                df: df,
                firmante: this.signersDataCopy,
            };

            try {
                await axios.post(`${process.env.VUE_APP_API_URL}/api/sendCargaMasiva`, body, { headers });
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        },


        removeErrorMessage(errorType) {
            if (errorType === 'errorMessage') {
                this.errorMessage = '';
            }
        },
    }

}
</script>

<style>
.cursor-not-allowed {
    cursor: not-allowed;
}

.step-container {
    padding: 1rem;
}

.step-header {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}

.input-group {
    display: flex;
    align-items: center;
}

.input-text {
    border-bottom: 1px solid #ccc;
    margin-right: 1rem;
    padding: 0.5rem;
    width: 16rem;
}

.button-group {
    display: flex;
    align-items: center;
}

.btn-back,
.btn-next {
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
}

.btn-back {
    color: #1d4ed8;
    margin-right: 1rem;
}

.icon-back {
    margin-right: 0.5rem;
    background-color: #1d4ed8;
    color: white;
    padding: 0.25rem;
    border-radius: 50%;
}

.btn-next {
    color: #1d4ed8;
}

.icon-next {
    margin-left: 0.5rem;
    background-color: #1d4ed8;
    color: white;
    padding: 0.25rem;
    border-radius: 50%;
}

.error-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fee2e2;
    color: #b91c1c;
    padding: 0.5rem;
    border-radius: 0.25rem;
    margin: 1rem 0;
}

.error-text {
    font-weight: bold;
}

.error-close {
    background: none;
    border: none;
    cursor: pointer;
}

.signature-position {
    margin-left: 1.5rem;
}

.signature-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.select-group {
    display: flex;
    align-items: center;
}

.select-input {
    border-bottom: 2px solid #ccc;
    padding: 0.5rem;
    margin-left: 0.5rem;
}

.info-icon {
    margin-left: 0.5rem;
    cursor: pointer;
}

.info-message {
    position: absolute;
    background-color: #e5e7eb;
    padding: 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.dni-request {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    margin-top: 1rem;
}

.checkbox {
    margin-right: 0.5rem;
}

.file-upload {
    margin: 1.5rem;
}

.file-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    border: 4px dashed #3b82f6;
    cursor: pointer;
}

.file-icon {
    color: #9ca3af;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.file-text {
    color: #9ca3af;
}

.file-button {
    background-color: #3b82f6;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
}

.loading-message {
    text-align: center;
    margin: 1.5rem;
}

.loading-bar {
    width: 6rem;
    height: 0.25rem;
    background-color: #3b82f6;
    border-radius: 0.25rem;
    animation: pulse 1s infinite;
}

.loading-text {
    color: #6b7280;
    margin-top: 0.5rem;
}

.file-list {
    margin: 1.5rem;
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #bfdbfe;
    padding: 0.5rem;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
}

.file-name {
    color: black;
}

.file-remove {
    background: none;
    border: none;
    cursor: pointer;
}

.pdf-viewer-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>