<template>
    <div class="step-header border-b border-gray-300 px-20 py-3 text-sm flex justify-between items-center">
        <div class="flex items-center">
            <p :class="{ 'text-blue-500 font-semibold': currentStep === 1, 'text-gray-400': currentStep !== 1 }">
                1. Cargar firmantes
            </p>
        </div>
        <div class="flex items-center">
            <p :class="{ 'text-blue-500 font-semibold': currentStep === 2, 'text-gray-400': currentStep !== 2 }">
                2. Cargar documento
            </p>
        </div>
        <div class="flex items-center">
            <p :class="{ 'text-blue-500 font-semibold': currentStep === 3, 'text-gray-400': currentStep !== 3 }">
                3. Enviar
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepHeader',
    props: {
        currentStep: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>
.step-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d1d5db;
    padding: 0.75rem 5rem;
    font-size: 0.875rem;
}

.text-blue-500 {
    color: #3b82f6;
}

.text-gray-400 {
    color: #9ca3af;
}

.font-semibold {
    font-weight: 600;
}
</style>