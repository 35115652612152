<template>
  <main class="flex p-4 pt-0 lg:ml-56 bg-gray-100">
    <div class="rounded-2xl w-full overflow-x-auto bg-white">
      <!-- Step Header -->
      <StepHeader :currentStep="currentStep" />

      <div v-if="loading" class="m-6 text-center">
        <div class="inline-block w-24 h-1 bg-blue-500 rounded-full animate-pulse"></div>
        <p class="mt-2 text-gray-500">Cargando...</p>
      </div>


      <!-- Step 1: Cargar firmantes -->
      <Step1Signers v-if="currentStep === 1" :signers="localSigners" :signer-error="localSignerError"
        :signer-error-message="localSignerErrorMessage" :max-signers-error="localMaxSignersError"
        :max-signers-error-message="localMaxSignersErrorMessage" @update-signers="updateLocalSigners"
        @update-signer="updateSingleSigner" @next-step="handleNextStep"
        @update:signerError="(val) => localSignerError = val"
        @update:maxSignersError="(val) => localMaxSignersError = val" />

      <!-- Step 2: Cargar documento -->
      <Step2Document v-if="currentStep === 2" :signersData="localSigners" @next-step="handleNextStep"
        @previous-step="previousStep" />

      <!-- <Step2Document v-if="currentStep === 2" :documentIdError="documentIdError"
        :documentIdErrorMessage="documentIdErrorMessage" :signersData="localSigners" @update-coords="updateLocalSigners"
        @next-step="handleNextStep" @previous-step="previousStep" /> -->


      <!-- Step 3: Enviar -->
      <Step3Send v-if="currentStep === 3" :signersData="step3Body" @previous-step="previousStep" />

      <!-- Popup and Modals -->
      <PopUpComponent :show="showPopUp" :message="PopUpMessage" />
      <ModalPurchaseComponent v-if="showLimitExceededModal" :show="showLimitExceededModal"
        @close="showLimitExceededModal = false" />
    </div>
  </main>
</template>


<script>
import PopUpComponent from '../../components/PopUpComponent.vue';
import ModalPurchaseComponent from '../../components/ModalPurchaseComponent.vue';
import Step1Signers from './Step1Signers.vue';
import Step2Document from './Step2Document.vue';
import Step3Send from './Step3Send.vue';
import StepHeader from './StepHeader.vue';



export default {
  components: {
    StepHeader,
    Step1Signers,
    Step2Document,
    Step3Send,
    PopUpComponent,
    ModalPurchaseComponent,
  },
  data() {
    return {
      currentStep: 1,
      signerError: false,
      signerErrorMessage: 'Por favor, completa todos los datos de los firmantes.',
      maxSignersError: false,
      maxSignersErrorMessage: 'Se ha alcanzado el límite máximo de firmantes (6).',
      fileNames: [],
      documentId: '',
      base64Doc: null,
      isFileUploaded: false,
      isPdfLoaded: false,
      loading: false,
      errorMessage: '',
      campains: [],
      selectedCampainName: '',
      send_dni: false,
      validate_dni: false,
      todas_las_hojas: false,
      todasLasSolicitudes: { urls: [] },
      copiedLinks: [],
      hasChanges: false,
      showPopUp: false,
      PopUpMessage: 'Por favor corrobore los datos',
      showLimitExceededModal: false,

      localSigners: [{ name: '', lastName: '', dni: '', areaCode: '', phoneNumber: '', contact: 'wpp', position_x: null, position_y: null, page: null, signerNumber: 0 }],
      localSignerError: false,
      localSignerErrorMessage: '',
      localMaxSignersError: false,
      localMaxSignersErrorMessage: 'Se ha alcanzado el límite máximo de firmantes (6).',

      coords: [],
      flow_id: 1,
      step3Body: {},
    };
  },
  methods: {
    updateLocalSigners(newSigners) {
      this.localSigners = newSigners;
    },
    updateSingleSigner({ index, ...newSigner }) {
      this.localSigners = this.localSigners.map((s, i) => i === index ? newSigner : s);
    },
    updateSendDni(val) {
      this.send_dni = val;
    },
    updateValidateDni(val) {
      this.validate_dni = val;
    },
    updateTodasLasHojas(val) {
      this.todas_las_hojas = val;
    },
    updateDocumentId(val) {
      this.documentId = val;
    },

    // Methods for handling steps, signers, files, etc.
    async handleNextStep(data) {
      if (this.loading) return; // Prevent multiple clicks

      this.loading = true;

      try {
        // Verificar si existen dos phoneNumber iguales
        const phoneNumbers = this.localSigners.map(signer => signer.phoneNumber);
        const uniquePhoneNumbers = new Set(phoneNumbers);
        if (phoneNumbers.length !== uniquePhoneNumbers.size) {
          this.signerError = true;
          return; // No avanzar al siguiente paso debido a números de teléfono duplicados
        }

        // Limpiar msj de error de firmantes al avanzar de paso después de haber ingresado los nombres
        if (this.currentStep === 1) {

          this.signerError = false;
        }


        // Crear doc y mostrar popup
        if (this.currentStep === 2) {
          this.localSigners = data.signersDataToReturn;
          this.send_dni = data.send_dni;
          this.validate_dni = data.validate_dni;
          this.todas_las_hojas = data.todas_las_hojas;
          this.documentId = data.documentId;
          this.base64Doc = data.base64Doc;

          this.prepareBody();
          this.loading = true;

        }


        // Si pasa todas las validaciones, avanzar al siguiente paso
        if (!this.showLimitExceededModal) {
          if (this.currentStep === 2) {
            this.showPopUp = true;
            setTimeout(() => {
              this.showPopUp = false;
            }, 5000);
          }
          this.errorMessage = '';
          this.currentStep++;
        }
      } finally {
        this.loading = false;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    copyLink(link) {
      navigator.clipboard.writeText(link);
      this.copiedLinks.push(link);
      setTimeout(() => {
        this.copiedLinks = this.copiedLinks.filter((l) => l !== link);
      }, 2000);
    },
    prepareBody() {
      // TODO: elegir mejor el flow_id... que se actualize cuando se marque
      if (this.send_dni) {
        this.flow_id = 2;
        if (this.validate_dni) {
          this.flow_id = 4;
        }
      } else if (this.validate_dni) {
        this.flow_id = 3;
      }

      this.localSigners = this.localSigners.map((signer, index) => ({
        signerNumber: index + 1,
        name: `${signer.name} ${signer.lastName}`,
        dni: signer.dni,
        email: signer.email,
        phone: `${signer.areaCode}${signer.phoneNumber}`,
        method: signer.contact,
        position_x: signer.position_x,
        position_y: signer.position_y,
        page: this.todas_las_hojas ? 0 : signer.page, // If todas_las_hojas is true, set page to 0
      }));


      // TODO: arreglar el client name
      const body = {
        datos_firmantes: this.localSigners,
        id_custom_client: this.documentId ?? null,
        document: this.base64Doc,
        webhook_url: null,
        cliente: 'luis',
        fixed_signature: {
          fixed_signature: true,
          dni_validation: this.validate_dni
        }, // we are not using free signature anymore (its campaing 0)
        flow_id: this.flow_id,
        firma_en_todas_las_hojas: this.todas_las_hojas,

        send_dni: this.send_dni,
      };

      console.log('body', body);

      this.step3Body = body;
    },
  },
};
</script>
