<template>
  <div class="flex-container">
    <!-- Current Page Display -->
    <div class="current-page-display">
      Página {{ currentPage }} / {{ totalPages }}
    </div>

    <div ref="pdfContainer" class="pdf-container" @scroll="onScroll"></div>

    <div class="button-container">
      <button v-for="(box, index) in boxes" :key="box.id" @click="addDraggableBox(box.id, index, signers)"
        :disabled="box.isAdded" :style="{
          backgroundColor: box.isAdded ? '#cccccc' : colors[index % colors.length],
          color: box.isAdded ? '#666666' : 'white',
        }" class="styled-button">
        {{ signers[index].name }}
      </button>
    </div>
  </div>
</template>


<script>
import { ref, onMounted, watch } from 'vue';
import * as pdfjsLib from 'pdfjs-dist/webpack';

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export default {
  name: 'PdfViewer',
  props: {
    pdfData: {
      type: String,
      required: true,
    },
    signers: {
      type: Array,
      default: () => [], // Set a default empty array
    },
  },
  setup(props, { emit }) {
    const pdfContainer = ref(null);
    const currentPage = ref(1);
    const totalPages = ref(0);
    const boxes = ref([]);
    const colors = ['#FF9803', '#E82063', '#9C27B3', '#2396F3', '#4DB050', '#FFEB3C'];
    const signersPDF = ref([]);

    const initializeBoxes = () => {
      boxes.value = []; // Clear old boxes before initializing

      for (let i = 1; i <= props.signers.length; i++) {
        boxes.value.push({ id: `box${i}`, isAdded: false });
      }
    };

    const initializeExistingBoxes = () => {

      setTimeout(() => { // Delay execution slightly
        document.querySelectorAll('.draggable-box').forEach(box => box.remove());

        props.signers.forEach((signer, index) => {
          if (signer.position_x !== null && signer.position_y !== null) {
            const box = boxes.value.find(b => b.id === `box${index + 1}`);
            if (box) {
              addDraggableBox(box.id, index, props.signers);
              box.isAdded = true;

              box.sign.position_x = signer.position_x;
              box.sign.position_y = signer.position_y;
              box.sign.page = signer.page;
              box.sign.phone = signer.phone;
              box.sign.method = signer.contact;


              const canvases = pdfContainer.value?.getElementsByTagName('canvas');
              if (!canvases || canvases.length === 0) return;

              const pageIndex = parseInt(signer.page) - 1; // PDF pages often start at 1

              if (isNaN(pageIndex) || pageIndex < 0) {
                console.error(`Invalid page number for signer ${index}: ${signer.page}`);
                return;
              }

              const currentCanvas = canvases[pageIndex];
              if (!currentCanvas) {
                console.error(`Canvas not found for page ${pageIndex + 1}`);
                return;
              }

              let boxElement = currentCanvas.querySelector(`.draggable-box[data-id="${box.id}"]`);
              if (!boxElement) {
                boxElement = document.createElement('div');
                boxElement.classList.add('draggable-box');
                boxElement.setAttribute('data-id', box.id);
                boxElement.setAttribute('data-page', signer.page);

              }

              // Correct positioning logic
              const containerRect = currentCanvas.getBoundingClientRect();
              boxElement.style.left = `${(signer.position_x / 476) * (containerRect.width - boxElement.offsetWidth)}px`;
              boxElement.style.top = `${containerRect.height - (signer.position_y / 716) * containerRect.height}px`;


            }
          } else {
            const box = boxes.value.find(b => b.id === `box${index + 1}`);
            if (box) {
              box.isAdded = false;
              checkAllBoxesAdded();
            }
          }
        });
      }, 10); // Small delay to ensure Vue updates before execution
    };

    watch(() => props.signers, (newSigners) => {
      signersPDF.value = newSigners.map(signer => ({ ...signer }));
      initializeBoxes();  // Reset boxes
      initializeExistingBoxes(); // Apply new positions
    }, { deep: true, immediate: true });

    const renderPDF = async () => {
      const loadingTask = pdfjsLib.getDocument({ data: atob(props.pdfData) }); // Decode the base64 string

      const pdf = await loadingTask.promise;
      totalPages.value = pdf.numPages;
      pdfContainer.value.innerHTML = '';

      for (let pageNum = 1; pageNum <= totalPages.value; pageNum++) {
        await renderPage(pdf, pageNum);
      }
    };

    const renderPage = async (pdf, pageNum) => {
      const page = await pdf.getPage(pageNum);

      // Define the maximum display dimensions
      const maxDisplayWidth = 476;
      const maxDisplayHeight = 716;

      // Get the natural dimensions of the page
      const viewport = page.getViewport({ scale: 1 });
      // const aspectRatio = viewport.width / viewport.height;

      // Calculate scale to fit either width or height within the target dimensions
      const widthScale = maxDisplayWidth / viewport.width;
      const heightScale = maxDisplayHeight / viewport.height;
      const scale = Math.min(widthScale, heightScale);

      // Adjust the viewport based on the calculated scale
      const scaledViewport = page.getViewport({ scale });

      // Set the dimensions based on the scaled viewport
      const displayWidth = scaledViewport.width;
      const displayHeight = scaledViewport.height;

      // Create a wrapping container for the canvas
      const pageContainer = document.createElement('div');
      pageContainer.classList.add('page-container');
      pageContainer.style.position = 'relative';
      pageContainer.style.width = `${displayWidth + 5}px`;
      pageContainer.style.height = `${displayHeight}px`;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = displayWidth;
      canvas.height = displayHeight;

      pageContainer.appendChild(canvas);
      pdfContainer.value.appendChild(pageContainer);

      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport,
      };

      await page.render(renderContext).promise;

      if (pageNum === currentPage.value) {
        canvas.classList.add('current-page');
      }
    };


    const onScroll = () => {
      const canvases = pdfContainer.value.getElementsByTagName('canvas');
      let visiblePageIndex = -1;

      for (let i = 0; i < canvases.length; i++) {
        const rect = canvases[i].getBoundingClientRect();
        const isHalfVisible = rect.top < window.innerHeight && rect.bottom > window.innerHeight / 2;
        if (isHalfVisible) {
          visiblePageIndex = i + 1;
          break;
        }
      }

      if (visiblePageIndex !== -1 && visiblePageIndex !== currentPage.value) {
        currentPage.value = visiblePageIndex;
        highlightCurrentPage();
      }
    };

    const highlightCurrentPage = () => {
      const canvases = pdfContainer.value.getElementsByTagName('canvas');
      for (let i = 0; i < canvases.length; i++) {
        if (i === currentPage.value - 1) {
          canvases[i].classList.add('current-page');
        } else {
          canvases[i].classList.remove('current-page');
        }
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        highlightCurrentPage();
      }
    };

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        highlightCurrentPage();
      }
    };

    const hexToRgba = (hex, alpha) => {
      const hexValue = hex.replace('#', '');
      const r = parseInt(hexValue.substring(0, 2), 16);
      const g = parseInt(hexValue.substring(2, 4), 16);
      const b = parseInt(hexValue.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const addDraggableBox = (boxId, index, signers) => {
      const canvases = pdfContainer.value?.getElementsByTagName('canvas');

      if (!canvases || canvases.length === 0) {
        console.error("No canvases found in pdfContainer.");
        return;
      }

      let currentCanvas;
      if (signers[index]?.page !== null && signers[index]?.page !== undefined) {
        currentCanvas = canvases[signers[index].page - 1];
      } else {
        const fallbackIndex = (currentPage.value || 1) - 1;
        currentCanvas = canvases[fallbackIndex] || null;
      }

      // Ensure `currentCanvas` is valid before accessing `parentElement`
      if (!currentCanvas) {
        console.error("currentCanvas is undefined or out of bounds.");
        return;
      }

      const existingBox = currentCanvas.parentElement?.querySelector(`.draggable-box[data-id="${boxId}"]`);
      if (existingBox) return; // Prevents duplicate boxes on the same page


      const box = document.createElement('div');
      box.classList.add('draggable-box');
      box.style.position = 'absolute';
      box.style.width = '100px';
      box.style.height = '50px';
      box.style.backgroundColor = hexToRgba(colors[index % colors.length], 0.5);
      box.style.cursor = 'move';

      box.setAttribute('data-id', boxId); // Set data-id attribute to identify the box


      box.innerHTML = `<span style="color: white; font-weight: bold;">${signers[index]?.name || 'Unnamed'}</span>`;

      const closeButton = document.createElement('button');
      closeButton.innerText = 'X';
      closeButton.classList.add('close-button');
      closeButton.onclick = () => {
        box.remove();
        const boxData = boxes.value.find(b => b.id === boxId);

        if (boxData) {
          boxData.isAdded = false;
          checkAllBoxesAdded();
        }
      };
      box.appendChild(closeButton);


      // Check if signer already has predefined position
      const signerData = signers[index];
      if (signerData.position_x !== null && signerData.position_y !== null) {
        const containerRect = currentCanvas.getBoundingClientRect();

        // Convert stored position to pixel coordinates
        box.style.left = `${(signerData.position_x / 476) * (containerRect.width - 100)}px`;
        box.style.top = `${(1 - signerData.position_y / 716) * (containerRect.height - 50)}px`;
      } else {
        box.style.top = '0px';
        box.style.left = '0px';
      }


      let isDragging = false;
      let startX, startY, initialX, initialY;

      const onMouseDown = (e) => {
        isDragging = true;
        startX = e.clientX;
        startY = e.clientY;
        initialX = box.offsetLeft;
        initialY = box.offsetTop;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      };

      // Need to update this if any changes con renderPDF
      const updateBoxSign = () => {
        const containerRect = currentCanvas.getBoundingClientRect();

        const styleLeft = parseInt(box.style.left) || 0;
        boxData.sign.position_x = Math.round((styleLeft / (containerRect.width - box.offsetWidth)) * 476);

        const maxStyleTop = containerRect.height - box.offsetHeight; // Ensuring the box does not overflow
        const styleTop = Math.min(parseInt(box.style.top), maxStyleTop);
        boxData.sign.position_y = Math.round((maxStyleTop - styleTop) / maxStyleTop * 716);

        boxData.sign.page = currentPage.value;
      };

      const onMouseMove = (e) => {
        if (isDragging) {
          const dx = e.clientX - startX;
          const dy = e.clientY - startY;
          let newX = initialX + dx;
          let newY = initialY + dy;

          const containerRect = currentCanvas.getBoundingClientRect();
          const boxRect = box.getBoundingClientRect();

          if (newX < 0) newX = 0;
          if (newY < 0) newY = 0;
          if (newX + boxRect.width > containerRect.width) newX = containerRect.width - boxRect.width;
          if (newY + boxRect.height > containerRect.height) newY = containerRect.height - boxRect.height;

          box.style.left = `${newX}px`;
          box.style.top = `${newY}px`;

          updateBoxSign();
          checkAllBoxesAdded();
        }
        updateBoxSign();
      };



      const onMouseUp = () => {
        isDragging = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      box.addEventListener('mousedown', onMouseDown);

      currentCanvas.parentElement.appendChild(box);

      const boxData = boxes.value.find(b => b.id === boxId);
      if (boxData) {

        const y = signers[index].position_y ?? 0;  // Use nullish coalescing to handle null/undefined
        const x = signers[index].position_x ?? 0;
        const page = signers[index].page ?? 1;

        boxData.isAdded = true;
        boxData.sign = {
          position_y: y, position_x: x, page: page, name: signers[index].name,
          signerNumber: index, dni: signers[index].dni, email: signers[index].email,
          phone: signers[index].phone, method: signers[index].method,
        };
        checkAllBoxesAdded(); // Check if all boxes are added
      }
    };


    const checkAllBoxesAdded = () => {
      const allAdded = boxes.value.every(box => box.isAdded);
      if (allAdded) {
        // return the signers with the coordenates and page
        boxes.value.forEach(box => {
          const signer = signersPDF.value.find(signer => signer.signerNumber === box.sign.signerNumber);
          if (signer) {
            signer.position_x = box.sign.position_x;
            signer.position_y = box.sign.position_y;
            signer.page = box.sign.page;

          }
        });

        emit('signers-load', true);
        // emits the signers coordenates and page
        emit('coords', signersPDF.value);
      } else {
        emit('signers-not-load', true);
      }
    };

    onMounted(async () => {
      initializeBoxes();
      await renderPDF();
      initializeExistingBoxes();
    });

    watch(currentPage, highlightCurrentPage);

    return {
      pdfContainer,
      currentPage,
      totalPages,
      nextPage,
      previousPage,
      onScroll,
      addDraggableBox,
      boxes,
      colors,
    };
  },
};

</script>


<style>
html,
body {
  height: 100%;
  /* Ensure body takes full height */
  /* overflow: hidden; */
  /* Prevent overall scrolling */
}

.pdf-viewer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* hide scrolling bar in pdf viewer */
/* 
.pdf-container::-webkit-scrollbar {
  display: none;
}
*/
/* Modern and clean scrollbar */
.pdf-container::-webkit-scrollbar {
  width: 10px;
  /* Slightly wider for better visibility */
}

.pdf-container::-webkit-scrollbar-track {
  background: #f3f3f3;
  /* Light gray for subtle contrast */
  border-radius: 10px;
}

.pdf-container::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  /* Neutral gray for visibility */
  border-radius: 10px;
  border: 2px solid #f3f3f3;
  /* Creates separation from the track */
  transition: background 0.3s ease;
}

.pdf-container::-webkit-scrollbar-thumb:hover {
  background: #888888;
  /* Darker gray for better interaction feedback */
}

.current-page-display {
  position: absolute;
  top: 10px;
  left: 10%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  z-index: 10;
}





.button-container {
  position: sticky;
  /* Change to sticky for better behavior */
  top: 0;
  /* Stick to the top of the flex-container */

  z-index: 10;
  /* Ensure it appears above other content */
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Add some space between button and PDF container */
  margin-left: 10px;

}

.styled-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.styled-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.styled-button:not(:disabled):hover {
  background-color: #0056b3;
}

.pdf-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(100% + 2px);
  /* Adds 2px to 100% width */


  height: 700px;
  overflow-y: auto;
  /* Allow vertical scrolling within the PDF viewer */
  position: relative;
  /* For absolute positioning of draggable box */
}



canvas {
  border: 1px solid #ccc;
  /* Default border for canvases */
  position: relative;
  /* For absolute positioning of draggable box */
}

/* Style for the current page */
.current-page {
  border: 2px solid blue;
  /* Blue border for the current page */
}

.draggable-box {
  border: 1px solid #000;
  position: absolute;
  user-select: none;
  z-index: 10;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 2px 5px;
  font-size: 12px;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.close-button:hover {
  opacity: 1;
}

.flex-container {
  display: flex;
  /* Align items side by side */
  align-items: flex-start;
  /* Ensures both containers are at the top */
  position: relative;
  /* Allows absolute positioning of children */
}
</style>