<template>
  <main class="flex flex-col p-4 pt-0 lg:ml-56 lg:p-8 bg-gray-100 relative min-h-screen">
    <div class="rounded-2xl w-full overflow-x-auto bg-white p-6 md:p-8 shadow-lg">
      <h1 class="text-4xl md:text-5xl font-bold text-center mt-4" style="color: #032141;">
        Elige el mejor plan para ti.
      </h1>

      <div class="flex justify-center items-center mt-4">
        <span class="text-lg md:text-2xl font-bold text-center" style="color: #032141;">Mensual</span>
        <label class="relative inline-flex items-center cursor-pointer mx-3">
          <input type="checkbox" class="sr-only peer" @change="togglePlan">
          <div class="w-14 h-7 bg-gray-300 peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer 
      peer-checked:after:translate-x-7 peer-checked:after:border-white after:content-[''] 
      after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 
      after:border after:rounded-full after:h-6 after:w-6 after:transition-all 
      peer-checked:bg-green-500">
          </div>
        </label>
        <span class="text-lg md:text-2xl font-bold text-center" style="color: #032141;">Anual (20% Descuento)</span>
      </div>

      <div class="flex flex-nowrap justify-center mt-9 mb-6 gap-2">
        <div v-for="(plan, index) in plans" :key="index" class="w-full md:w-1/4 lg:w-1/4 px-1 text-center">

          <div
            class="px-4 border-2 flex flex-col items-center justify-between h-full rounded-2xl transition-all duration-300"
            style="border: 2px solid #FEBFCC;">
            <div class="mt-4 flex-grow flex flex-col items-center">
              <h2 class="font-bold transition-all duration-300" style="color: #005941;">{{ plan.name }}</h2>
              <h3 class="font-bold text-2xl transition-all duration-300" style="color: #032141;">{{ plan.signatures }}
                firmas / {{ isAnnual ? 'año' : 'mes' }}</h3>
              <p class="mt-4 font-semibold transition-all duration-300"><span class="text-4xl">{{ plan.price }}</span>
                u$d / {{ isAnnual ? 'año' : 'mes' }}</p>
              <p v-if="plan.discount !== undefined" class="font-semibold transition-all duration-300"
                style="color: #2A6465;">
                <span class="text-2xl">{{ plan.discount || '&nbsp;' }}</span>
              </p>

              <p class="text-left self-start mt-4 font-semibold transition-all duration-300">Incluye:</p>

              <ul class="text-left px-4 text-sm mt-4 text-black-800 list-disc transition-all duration-300"
                style="flex-grow: 1; min-height: 120px;">
                <li class="mb-1">{{ plan.costPerSignature }} final por firma</li>
                <li class="my-1">{{ plan.users }}</li>
                <li class="my-1">Plataforma centralizada</li>
                <li class="my-1">Validación de identidad</li>
                <li class="my-1">{{ plan.support }}</li>
                <li v-if="plan.cargaMasiva" class="my-1">Carga masiva de Docs</li>
              </ul>

              <button
                class="custom-button py-2 px-6 mt-3 mb-4 font-semibold text-sm rounded-2xl transition-all duration-300"
                @click="handleClick(plan.signatures)">
                COMPRAR {{ plan.signatures }}
              </button>
            </div>
          </div>


        </div>
      </div>

      <div class="flex items-start">
        <!-- List takes half of the container width -->
        <ul class="mt-6 text-left text-sm md:text-base list-disc w-1/2">
          <li>El cobro se realizará 100% en su moneda local.</li>
          <li>Las firmas se acreditan automáticamente.</li>
          <li>Sin permanencia mínima.</li>
        </ul>

        <div class="ml-6 px-4 flex w-2/5 h-1/2 rounded-2xl transition-all duration-300 mt-4"
          style="background-color: #F9F1E6;">
          <!-- Container for the paragraph and lists, set to relative to control positioning of elements inside -->
          <div class="relative w-full">
            <div class="flex w-full">
              <!-- Left side of the box -->
              <ul class="text-left text-sm md:text-base list-disc w-1/2 mt-2 pl-6">
                <li class="list-none -ml-4 font-bold">Precisa más?</li>
                <li>Plan más grande</li>
                <li>Más usuarios</li>
              </ul>

              <!-- Right side of the box -->
              <ul class="text-left text-sm md:text-base list-disc w-1/2 pl-6 mt-2">
                <li>API integración</li>
                <li>Número propio</li>
                <li>Pedir otros Docs o Info</li>
              </ul>
            </div>
          </div>
        </div>


        <!-- WhatsApp Logo -->
        <a href="https://wa.link/m7i6ri" target="_blank" rel="noopener noreferrer">
          <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp Logo"
            class="bottom-2 right-2 w-20 h-20 mt-4 -ml-4">
        </a>

      </div>

    </div>
  </main>
</template>

<script setup>
import { ref, computed } from "vue";
import axios from "axios";
import { getCookie } from '../helpers/cookies';

const isAnnual = ref(false);

const plans = computed(() => [
  {
    name: "Salve una Semilla 🌱",
    signatures: isAnnual.value ? 120 : 10,
    price: isAnnual.value ? 96 : 10,
    discount: isAnnual.value ? "20% descuento" : "",
    costPerSignature: isAnnual.value ? "u$d 0,80" : "u$d 1,00",
    support: "Soporte por mail",
    users: "1 usuario",
    cargaMasiva: false,
  },
  {
    name: "Salve un Planta 🪴",
    signatures: isAnnual.value ? 240 : 20,
    price: isAnnual.value ? 144 : 15,
    discount: isAnnual.value ? "40% descuento" : "25% descuento",
    costPerSignature: isAnnual.value ? "u$d 0,60" : "u$d 0,75",
    support: "Soporte por mail",
    users: "1 usuario",
    cargaMasiva: false,
  },
  {
    name: "Salve un Árbol 🌳",
    signatures: isAnnual.value ? 600 : 50,
    price: isAnnual.value ? 336 : 35,
    discount: isAnnual.value ? "44% descuento" : "30% descuento",
    costPerSignature: isAnnual.value ? "u$d 0,56" : "u$d 0,70",
    support: "Soporte por WhatsApp",
    users: "3 usuarios",
    cargaMasiva: false,
  },
  {
    name: "Salve un Bosque 🌲🌲",
    signatures: isAnnual.value ? 1200 : 100,
    price: isAnnual.value ? 576 : 60,
    discount: isAnnual.value ? "52% descuento" : "40% descuento",
    costPerSignature: isAnnual.value ? "u$d 0,48" : "u$d 0,60",
    support: "Soporte por WhatsApp",
    users: "6 usuarios",
    cargaMasiva: true,
  }
]);

const togglePlan = () => {
  isAnnual.value = !isAnnual.value;
};

const handleClick = async (signatures) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': getCookie('token')
  };
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/generatePaiementDlocal?amount=${signatures}`, { headers });
    const redirectUrl = response.data.url;
    window.open(redirectUrl, '_blank');
  } catch (error) {
    console.error('API call failed:', error);
  }
};
</script>

<style scoped>
.custom-button {
  background-color: #005941;
  color: white;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #004030;
}

/* Add smooth transitions to all elements */
.transition-all {
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
}
</style>