<template>
    <div class="server-down-container">
        <div class="server-down-content">
            <div class="image-container">
                <img src="../assets/img/wapi-banner-3x.png" alt="Server Offline" class="server-down-image" />
            </div>
            <h1 class="server-down-title">Server is Currently Offline</h1>
            <p class="server-down-message">
                We're experiencing technical difficulties. Please try again later.
            </p>
            <button @click="retryConnection" class="retry-button">
                Retry Connection
            </button>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
    name: 'ServerDown',
    setup() {
        const router = useRouter();

        const retryConnection = () => {
            router.push('/');
        };

        return {
            retryConnection
        };
    }
};
</script>
<style scoped>
.server-down-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9fafb;
}

.server-down-content {
    text-align: center;
    padding: 2rem;
    width: 100%;
    max-width: 600px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.server-down-image {
    width: 300px;
    height: auto;
    margin-bottom: 2rem;
    display: block;
}

.server-down-title {
    font-size: 2rem;
    color: #1f2937;
    margin-bottom: 1rem;
}

.server-down-message {
    font-size: 1.1rem;
    color: #6b7280;
    margin-bottom: 2rem;
}

.retry-button {
    padding: 0.75rem 1.5rem;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.retry-button:hover {
    background-color: #2563eb;
}
</style>