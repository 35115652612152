<template>
    <div v-if="loading" class="spinner-wrapper">
        <div class="loader"></div>
    </div>

    <div v-else class="m-6">
        <div v-for="(signer, index) in todasLasSolicitudes.urls" :key="'signer-' + index">
            <div class="flex items-center">
                <!-- Display name as text -->
                <span class="border-b-2 border-gray-300 p-2 pl-0 mr-6 mb-6 w-56">
                    {{ signer.name }}
                </span>
                <!-- Display contact as text -->
                <span class="border-b-2 border-gray-300 p-2 pl-0 mr-6 mb-6 w-56">
                    {{ signer.contact }}
                </span>
            </div>

            <div class="flex items-center justify-start mt-4 mb-8">
                <!-- Display link -->
                <a :href="signer.link" target="_blank"
                    class="bg-gray-200 p-2 rounded-xl text-blue-600 hover:text-blue-500 hover:underline text-lg mr-8">
                    {{ signer.link }}
                    <span class="ml-10">
                        <i v-if="!copiedLinks.includes(signer.link)" @click.prevent="copyLink(signer.link)"
                            class="fas fa-clone ml-2 mr-1 text-blue-400 hover:text-blue-300 cursor-pointer"></i>
                        <i v-else class="fas fa-check-circle ml-2 mr-1 text-green-400"></i>
                    </span>
                </a>
                <div class="flex items-center">
                    <!-- Display method icon -->
                    <i v-if="signer.method === 'mail'" class="fas fa-envelope text-xl mr-2 text-gray-500"></i>
                    <i v-else-if="signer.method === 'wpp'" class="fab fa-whatsapp text-xl mr-2 text-gray-500"></i>
                    <!-- Display checkbox (non-editable) -->
                    <input type="checkbox" v-model="signer.sendWithWapiIA" class="mr-2 ml-0.5 cursor-pointer h-4 w-4"
                        @change="handleCheckboxChange(signer)">
                    <p>Enviar con WapIA</p>
                    <!-- Info icon and tooltip -->
                    <i @mouseover="showInfoMessage = true" @mouseleave="showInfoMessage = false"
                        class="fas fa-info-circle text-xl ml-2 text-gray-500 hover:text-gray-400 cursor-pointer"></i>
                    <div v-if="showInfoMessage === true"
                        class="absolute bg-gray-200 rounded-lg text-sm py-1 px-6 w-1/5 top-1/5 text-gray-700 shadow-lg z-10 mr-4 mt-32">
                        <p>Envía el documento automáticamente mediante nuestro asistente de WhatsApp WapIA</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="flex justify-center">
            <router-link to="/requests?enviado=true">
                <button @click="sendDocuments"
                    class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-bold rounded-full">
                    <i class="fas fa-file mr-2"></i>Enviar documento
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getCookie } from '../../helpers/cookies';

export default {
    name: 'Step3Send',
    props: {
        signersData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            showInfoMessage: false,
            todasLasSolicitudes: {
                urls: [],
            },
            copiedLinks: [],
            send_dni: '',
            sendWithWapiIABodys: [],
            hasChanges: false,
            showPopUp: false,
            PopUpMessage: '',
            showLimitExceededModal: false,
            loading: true,
        };
    },
    mounted() {
        this.loading = true;
        this.createMission(this.signersData);
    },
    methods: {
        copyLink(link) { // Método para copiar el link
            const el = document.createElement('textarea');
            el.value = link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.copiedLinks.push(link);

            setTimeout(() => { // Eliminar el enlace copiado después de dos segundos
                const index = this.copiedLinks.indexOf(link);
                if (index !== -1) {
                    this.copiedLinks.splice(index, 1);
                }
            }, 2000);
        },
        async updateData() { // Datos actualizados de los input    
            // Mostrar popup
            this.PopUpMessage = 'Datos actualizados';
            this.showPopUp = true;
            this.hasChanges = false;


            // await this.updateMission();
            setTimeout(() => {
                this.showPopUp = false;
            }, 5000);

        },
        async loadWapia() {
            if (this.todasLasSolicitudes.urls.length > 0) {

                for (const element of this.todasLasSolicitudes.urls) {

                    const body = {
                        "phone": element.contact,
                        "flow_id": element.flow_id,
                        "variables": {
                            "company": getCookie('token'),
                            "name": element.name,
                            "sign_link": element.link,
                            "document_name": this.documentId ?? 'Documento',
                            "document_link": `https://wapi-doc.s3.us-east-2.amazonaws.com/${this.todasLasSolicitudes.id_seguimiento}.pdf`,
                            "id_custom": element.id_custom,
                            "send_dni": element.send_dni
                        }
                    };
                    this.sendWithWapiIABodys.push(body);
                }
            }
        },
        // Just send the first one... the rest do not need to be sent
        async sendDocuments() {
            if (!this.sendWithWapiIABodys.length) return;

            let url = 'https://api.wapifirma.com/wpp/sendFlow';
            const apis = ['6aLgTSxgZUl2uLWU8DTqVc6vEwBQqQr4',
                '4VQ2xegP2W258vQIdSPF8VFNOagsHTAD',
                'N0vVY2Q9tSmQHPLIkPte23UUZEHWM8Ce']

            if (apis.includes(getCookie('token'))) {
                url = 'https://api.wapifirma.com/rolsol/sendFlow';
            }

            let body = this.sendWithWapiIABodys[0]; // Only send the first document
            // Add the web flag to the body so that the API knows it's coming from the web and use default flow
            body['isWeb'] = true;

            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: { 'Content-Type': 'application/json' },
                data: body
            };

            await axios.request(config);
        },
        async createMission(signersData) {
            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': getCookie('token')
            };

            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/signatureRequest`, signersData, { headers });
                this.todasLasSolicitudes = response.data;


                for (let element of this.todasLasSolicitudes.urls) {
                    element.sendWithWapiIA = true;
                    element.send_dni = signersData.send_dni;
                    element.flow_id = signersData.flow_id;
                }

                await this.loadWapia();

                return this.todasLasSolicitudes;
            } catch (error) {
                if (error.response && error.response.status === 429) {
                    // Si la solicitud falla debido a "Demasiadas solicitudes", muestra la modal
                    this.showLimitExceededModal = true;
                    return this.showLimitExceededModal;
                } else {
                    // Manejar otros errores de manera diferente, si es necesario
                    console.error('Error en la solicitud:', error);
                }
            } finally {
                this.loading = false;
            }
        },
        handleCheckboxChange(signer) {
            // Verificar si el checkbox está tildado

            if (signer.sendWithWapiIA) {
                if (this.documentId === '') {
                    this.documentId = 'Documento';
                }

                const body = {
                    "phone": signer.contact,
                    "flow_id": signer.flow_id,
                    "variables": {
                        "company": getCookie('token'),
                        "name": signer.name,
                        "sign_link": signer.link,
                        "document_name": this.documentId ?? 'Documento',
                        "document_link": `https://wapi-doc.s3.us-east-2.amazonaws.com/${this.todasLasSolicitudes.id_seguimiento}.pdf`,
                        "id_custom": signer.id_custom,
                        "send_dni": signer.send_dni
                    }
                }
                this.sendWithWapiIABodys.push(body);
            } else {
                // deletes the body from the array
                for (let i = 0; i < this.sendWithWapiIABodys.length; i++) {
                    if (this.sendWithWapiIABodys[i].phone === signer.contact) {
                        this.sendWithWapiIABodys.splice(i, 1);
                    }
                }
            }

        },
    }
}
</script>

<style scoped>
/* Spinner styles */
.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full screen height */
}

/* Spinner styles */
.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>