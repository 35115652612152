<template>
  <div v-if="show" class="fixed inset-0 flex items-center justify-center z-50">
    <!-- Background overlay with fade-in animation -->
    <div class="fixed inset-0 bg-black opacity-50 transition-opacity duration-300 ease-in-out"
      @click="handleOverlayClick"></div>

    <!-- Modal content with subtle scaling animation -->
    <div
      class="relative bg-white p-8 rounded-xl shadow-lg w-full max-w-md transform transition-all duration-300 ease-in-out scale-100">
      <h3 class="text-2xl font-bold text-center text-gray-800 mb-4 flex items-center justify-center">
        Te quedaste sin firmas 😔

      </h3>
      <p class="text-gray-600 text-center mb-6">
        ¡Compra tu plan para no volver a firmar en papel! Aprovecha para firmar tus documentos de manera rápida y
        segura.
      </p>
      <div class="flex justify-center space-x-4">
        <router-link to="/purchase">
          <button @click="close"
            class="bg-green-500 hover:bg-green-600 text-white font-medium px-6 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105">
            Comprar Plan
          </button>
        </router-link>
        <button @click="close"
          class="bg-gray-300 hover:bg-gray-400 text-gray-700 font-medium px-6 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show'],
  methods: {
    close() {
      this.$emit('close'); // Emits the close event to the parent component
    },
    handleOverlayClick() {
      this.close(); // Close modal when clicking on the overlay
    }
  }
};
</script>
